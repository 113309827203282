.trending-page {
  max-width: 1200px;
  margin: 62px auto 0;
  display: grid;
  grid-template-columns: 60px 1fr 374px;

  @media screen and (max-width: 990px) {
    grid-template-columns: 60px 1fr;
    margin: 58px 20px 50px
  }

  @media screen and (max-width: 590px) {
    grid-template-columns: 1fr;
    margin: 58px 8px 50px;
  }

  .main {
    margin-top: -8px;
  }

  .right-side {
    position: relative;
    width: 100%;
    margin: 0px 0 0 14px;
  
    .right-side-container {
      position: fixed;
      width: 35%;
  
      @media screen and (min-width: 1200px) {
        width: 404px;
      }
  
    }
  }
} 