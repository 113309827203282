.sidebarinf-container {
  background: $color-2;
  border-radius: 20px;
  margin-bottom: 14px;
  padding: 20px;
  width: 356px;

  ul {

    li {
      margin: 5px 0;
      display: grid;
      align-items: center;
      background: $color-4;
      border-radius: 20px;
      padding: 16px;
      box-shadow: 5px 7px 20px 9px #ff7c7732;
      transition: .2s;

      &:hover {
        box-shadow: none;
      }

      div {
        height: 56px;
      }
    }
  }
}
