.bodyinf-container {
    display: grid;
    grid-template-columns: 156px 1fr;
    border: 2px solid $color-2;
    margin: 10px;
    padding: 16px;
    border-radius: 20px;
    margin: 8px auto;
    position: relative;
    min-height: 100px;
  
    @media screen and (max-width: 590px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 440px) {
      padding-top: 26px;
    }
  
    .bodyinf-left {
      @media screen and (max-width: 590px) {
        position: absolute;
        transform: translate(17px, 18px);
      }
    }
  
    .bodyinf-right {
      @media screen and (max-width: 590px) {
        margin-left: 18px;
        padding-top: 40px;
      }
      p {
        margin: 3px 0 9px;
        font-size: 1.1rem;
  
        @media screen and (max-width: 440px) {
          margin-top: -18px;
        }
      }
    }
  }
  